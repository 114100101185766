<template>
  <div role="button" class="tournament-button" @click="onClickButton">
    <template v-if="!booleanIsTournamentsModalOpen">
      <div class="tournament-button__logo">
        <img :src="leagueLogo" />
      </div>
      <div class="tournament-button__info">
        <div class="tournament-button__info__tournament">
          {{ objectTournamentSelected.name }}
        </div>
        <div class="tournament-button__info__season">
          {{ objectSeasonSelected.name }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="tournament-button__close-text">
        Cerrar
      </div>
      <div class="tournament-button__close-icon">
        <img src="/images/icn_close.png" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'NavBarTournamentButton',
  computed: {
    ...mapState('leagueGeneral', ['objectTournamentSelected', 'objectSeasonSelected', 'booleanIsTournamentsModalOpen']),
    leagueLogo() {
      if (this.objectTournamentSelected.id === 33) {
        return '/images/leagues/' + this.objectSeasonSelected.id + '.png';
      } else {
        return '/images/leagues/' + this.objectTournamentSelected.id + '.png';
      }
    },
  },

  methods: {
    ...mapMutations('leagueGeneral', ['setBooleanIsTournamentsModalOpen']),
    onClickButton() {
      this.setBooleanIsTournamentsModalOpen(!this.booleanIsTournamentsModalOpen);
    },
  },
};
</script>

<style scoped lang="scss">
.tournament-button {
  padding: 8px 16px 4px;
  border-radius: 25px;
  border: solid 1px #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
  cursor: pointer;

  &__logo {
    height: 36px;

    & > img {
      height: 36px;
    }
  }

  &__info {
    margin-left: 0.5em;
    text-align: left;
    font-family: Heebo, sans-serif;

    &__tournament {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: normal;
      text-align: left;
    }
    &__season {
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.8;
      letter-spacing: normal;
      text-align: left;
    }

    @media screen and (max-width: 420px) {
      &__tournament {
        font-size: 13px;
      }

      &__season {
        margin-top: 2px;
        font-size: 9px;
      }
    }
  }

  &__close-text {
    font-family: Heebo, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 4px;
  }

  &__close-icon {
    height: 20px;
    width: 20px;
    margin-left: 1em;
    margin-bottom: 4px;

    & > img {
      height: 13px;
      width: 13px;
    }
  }
}
</style>
